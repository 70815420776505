import { useRef } from "react";
import { Modal, Select } from "antd";
import dayjs from "dayjs";
import { Scheduler } from "@aldabil/react-scheduler";
import { pl } from "date-fns/locale";
import calendar_translations from "helpers/calendar_translations";
import { useWorkers } from "context/providers/workers";
import { useIssueCalendar } from "../../issue_calendar_context";

const IssueMonthCalendar = () => {
  const {
    date,
    holidays,
    issue_data,
    is_open_calendar,
    setOpenCalendar,
    setDate,
    getCalendarIssues,
    refetch,
    calendar_worker_id,
    setCalendarWorkerId,
  } = useIssueCalendar();
  const { workers_list_options } = useWorkers();

  const month_ref = useRef();
  const handleClose = () => {
    refetch();
    setOpenCalendar(false);
  };

  const day_name = month_ref?.current?.scheduler
    ?.selectedDate
    ? dayjs(
      month_ref.current.scheduler.selectedDate
    ).format("MMMM YYYY")
    : "-";

  return (
    <Modal
      destroyOnClose
      key={date}
      centered
      width={1400}
      size="large"
      maskClosable={false}
      open={is_open_calendar}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText={null}
      footer={null}
      className="month_calendar issue_month_calendar"
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `:root {
            --month-value: "${day_name}";
          }`,
        }}
      />
      <div className="issue_month_calendar__header">
        <Select
          style={{ minWidth: 200 }}
          value={calendar_worker_id}
          placeholder="Wybierz pracownika"
          filterOption={false}
          onChange={(id) => setCalendarWorkerId(id || null)}
          options={[
            { label: "Wszyscy", value: null },
            ...(workers_list_options || []),
          ]}
        />
        <h4>{issue_data?.identifier}</h4>
      </div>

      <Scheduler
        key={calendar_worker_id}
        ref={month_ref}
        agenda={false}
        locale={pl}
        view="month"
        height={600}
        deletable={false}
        draggable={false}
        editable={false}
        hourFormat={24}
        month={{
          weekStartOn: 1,
          cellRenderer: ({ start, end, day, ...rest }) => {
            const is_today =
              dayjs().format("DD-MM") ===
              dayjs(start).format("DD-MM");
            const week_day = dayjs(start).format("dd");
            const is_weekend = ["So", "Nd"].includes(
              week_day
            );
            const day_holidays = holidays.filter(
              ({ date }) =>
                date === dayjs(start).format("YYYY-MM-DD")
            );
            const is_same_month =
              dayjs(day).format("MM") ===
              dayjs(start).format("MM") ||
              dayjs(day).format("MM") ===
              dayjs(end).format("MM");
            const is_holiday = day_holidays?.length > 0;
            return (
              <div
                {...rest}
                className={`month-cell${is_today ? " today" : ""
                  }${is_holiday ? " holiday" : ""}${is_weekend ? " weekend" : ""
                  } ${!is_same_month ? "disabled" : ""}`}
              >
                <span className="month-cell__day">
                  {dayjs(start).format("DD")}
                </span>
              </div>
            );
          },
        }}
        disableViewNavigator={true}
        getRemoteEvents={getCalendarIssues}
        translations={calendar_translations}
        onEventClick={({ start }) =>
          setDate(dayjs(start).toDate())
        }
        onCellClick={(cell_date) => {
          setOpenCalendar(false);
          setDate(dayjs(cell_date).toDate());
        }}
      />
    </Modal>
  );
};

export default IssueMonthCalendar;

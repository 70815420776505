import {
  Alert,
  Avatar,
  Col,
  Modal,
  Row,
  Space,
  Typography,
  notification,
} from "antd";
import { getIssueStatusLabel } from "helpers/options/issue_statuses";
import dayjs from "dayjs";
import { useRef, useState, useEffect } from "react";
import { Scheduler } from "@aldabil/react-scheduler";
import { pl } from "date-fns/locale";
import { useCalendar } from "context/providers/calendar";
import calendar_translations from "helpers/calendar_translations";
import { api, handleErrors } from "helpers/api";
import EllipsisText from "components/EllipsisText";
import CustomEditor from "./components/CustomEditor";
import Tasks from "components/Tasks";
import getIssueColorByStatus from "helpers/getIssueColorByStatus";
import formatPhone from "helpers/phone_formatter";

const IssueDayCalendar = () => {
  const {
    day_holidays,
    day_events,
    day_tasks,
    day_calendar_date,
    update_count,
    setDayCalendarDate,
    is_open_modal,
    searchIssues,
    setUpdateCount,
    refetchDayTasks,
    refetchDayEvents,
  } = useCalendar();
  const [draggable_event, setDraggableEvent] =
    useState(null);
  var [time, setTime] = useState(new Date());

  const scheduler_ref = useRef();

  const handleClose = () => {
    setDayCalendarDate(null);
  };

  const handleConfirm = async () => {
    try {
      setUpdateCount(update_count + 1);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDrag = (_, date, event) =>
    setDraggableEvent({ date, event });

  const handleAsyncDrag = async () => {
    try {
      await api.put(
        `/issues/${draggable_event.event.event_id}/set_service_date`,
        {
          service_date: dayjs(
            draggable_event.date
          ).format(),
        }
      );
      notification.success({
        message: `Zaktualizowano date umówienia dla zgłoszenia ${draggable_event.event.identifier}`,
      });
      refetchDayEvents();
      setDraggableEvent(false);
    } catch (error) {
      notification.error({ message: handleErrors(error) });
    }
  };

  useEffect(() => {
    var timer = setInterval(
      () => setTime(new Date()),
      1000
    );
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const events = day_events.map(
    ({
      id,
      identifier,
      client_name,
      client_address,
      client_phone,
      status,
      service_date,
      service_date_delay,
      created_at,
      worker,
    }) => ({
      event_id: id,
      identifier,
      service_date_delay: service_date_delay,
      color: getIssueColorByStatus(status),
      client_name,
      client_address,
      client_phone,
      worker,
      service_date,
      created_at,
      textColor: status === "in_progress" ? "#000" : "#fff",
      title: (
        <>
          {worker?.id && (
            <>
              <Space style={{ marginBottom: 10 }}>
                <Avatar
                  style={{
                    backgroundColor: worker?.color,
                  }}
                >
                  {worker.initials}
                </Avatar>
                <Typography.Text
                  delete={worker?.is_deleted}
                >
                  <EllipsisText
                    text={worker.full_name}
                    limit={30}
                  />
                </Typography.Text>
              </Space>
              <br />
            </>
          )}
          <p className="m-b-1 m-t-0">
            {getIssueStatusLabel(status)}
          </p>
          <Typography.Text strong>
            {client_address}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            {dayjs(service_date).format("HH:mm")} -{" "}
            {dayjs(service_date)
              .add(1, "hour")
              .format("HH:mm")}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            {formatPhone(client_phone)}
          </Typography.Text>
          <br />
          <Typography.Text strong>
            <Typography.Link
              target="_blank"
              href={`/issues/${id}`}
              style={{
                color: "#000",
                textDecoration: "underline",
                lineHeight: "20px",
              }}
            >
              Przejdź do edycji
            </Typography.Link>
          </Typography.Text>
        </>
      ),
      start: dayjs(service_date).toDate(),
      end: dayjs(service_date).add(1, "hour").toDate(),
    })
  );

  return (
    <Modal
      className="day-calendar-modal"
      destroyOnClose
      centered
      width={1300}
      size="large"
      maskClosable={false}
      title=""
      open={is_open_modal}
      onCancel={handleClose}
      cancelText="Anuluj"
      okText={null}
      footer={null}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `:root {
            --time-value: "${time.toLocaleTimeString()}";
            --day-value: "${dayjs(day_calendar_date).format(
              "dddd"
            )}";
          }`,
        }}
      />
      <Row gutter={24}>
        <Col lg={12} md={24} xs={24}>
          <Scheduler
            ref={scheduler_ref}
            agenda={false}
            selectedDate={day_calendar_date}
            onSelectedDateChange={(date) =>
              setDayCalendarDate(date)
            }
            locale={pl}
            view="day"
            events={events}
            deletable={false}
            draggable={true}
            hourFormat={24}
            disableViewNavigator={true}
            translations={calendar_translations}
            eventRenderer={({ event, ...props }) => (
              <div
                {...props}
                className="calendar-panel"
                style={{
                  background: event.color,
                }}
              >
                <Typography.Text strong>
                  {event.client_address}
                </Typography.Text>
              </div>
            )}
            customEditor={(scheduler) => (
              <CustomEditor
                handleConfirm={handleConfirm}
                scheduler={scheduler}
                searchIssues={searchIssues}
                refetch={refetchDayEvents}
              />
            )}
            onEventDrop={handleDrag}
            day={{
              startHour: 5,
              endHour: 25,
              step: 60,
            }}
          />
        </Col>
        <Col
          lg={12}
          md={24}
          xs={24}
          style={{ marginTop: 55 }}
        >
          {day_holidays?.map(({ date, name }, index) => (
            <Alert
              style={{ marginBottom: 10 }}
              key={index}
              type="error"
              message={`${date} - ${name}`}
            />
          ))}
          <Tasks
            is_important={true}
            date={dayjs(day_calendar_date).format(
              "YYYY-MM-DD"
            )}
            data={day_tasks}
            refetch={() => {
              refetchDayTasks();
              setUpdateCount(update_count + 1);
            }}
          />
          <Tasks
            is_important={false}
            date={dayjs(day_calendar_date).format(
              "YYYY-MM-DD"
            )}
            data={day_tasks}
            refetch={() => {
              refetchDayTasks();
              setUpdateCount(update_count + 1);
            }}
          />
        </Col>
      </Row>
      <Modal
        title="Potwierdzenie"
        open={!!draggable_event?.event?.event_id}
        onOk={handleAsyncDrag}
        onCancel={() => setDraggableEvent(null)}
        cancelText="Anuluj"
        okText="Potwierdzam"
      >
        Czy na pewno chcesz zmienić godzinę umówienia <br />{" "}
        dla zgłoszenia{" "}
        <strong>
          {draggable_event?.event?.identifier}
        </strong>{" "}
        <br />z{" "}
        <strong>
          {dayjs(
            draggable_event?.event?.service_date
          ).format("HH:mm")}
        </strong>{" "}
        na{" "}
        <strong>
          {dayjs(draggable_event?.date).format("HH:mm")}
        </strong>
        ?
      </Modal>
    </Modal>
  );
};

export default IssueDayCalendar;

import { useMemo } from "react";
import {
  Button,
  Col,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import ListInput from "./components/Input";
import ListSelect from "./components/Select";
import ListDatePicker from "./components/DatePicker";
import NumericInput from "./components/NumericInput";
import {
  name_regexp,
  special_characters_regexp,
} from "helpers/regexp";

const getRegexp = (name) => {
  switch (name) {
    case "name":
    case "first_name":
    case "last_name":
      return name_regexp;
    case "ip_address":
      return new RegExp(/^[0-9.]{0,20}$/);
    case "post_code":
      return new RegExp(/^[0-9-]{0,6}$/);
    default:
      return special_characters_regexp;
  }
};

const Filters = ({
  columns,
  search_values,
  setSearchValues,
  onSearch,
  setQuery,
  paginate,
  setPaginate,
  total_count,
}) => {
  const sort_options = useMemo(() => {
    const data = [];
    columns
      .filter(({ sortable }) => sortable)
      .forEach(({ dataIndex, title }) => {
        data.push({
          label: `${title} malejąco`,
          value: `${dataIndex}__desc`,
        });
        data.push({
          label: `${title} rosnąco`,
          value: `${dataIndex}__asc`,
        });
      });
    return data;
  }, [columns]);

  return (
    <>
      <Row gutter={[12, 12]}>
        {columns
          .filter(({ searchable }) => searchable)
          .map(
            ({
              title,
              dataIndex: name,
              search_component = "input",
              search_type = "text",
              search_method,
              options = [],
            }) => (
              <Col xs={24} md={12} lg={4} xl={4}>
                {search_component === "input" &&
                  (search_type === "number" ? (
                    <NumericInput
                      search_type={search_type}
                      title={title}
                      value={search_values?.[name] || ""}
                      onChange={(value) =>
                        setSearchValues({
                          ...search_values,
                          [name]: value,
                        })
                      }
                      onBlur={() =>
                        onSearch({
                          name,
                          search_method,
                          value: search_values?.[name],
                        })
                      }
                      onPressEnter={() =>
                        onSearch({
                          name,
                          search_method,
                          value: search_values?.[name],
                        })
                      }
                    />
                  ) : (
                    <ListInput
                      search_type={search_type}
                      regexp={getRegexp(name)}
                      title={title}
                      value={search_values?.[name] || ""}
                      onChange={(value) =>
                        setSearchValues({
                          ...search_values,
                          [name]: value,
                        })
                      }
                      onBlur={() =>
                        onSearch({
                          name,
                          search_method,
                          value: search_values?.[name],
                        })
                      }
                      onPressEnter={() =>
                        onSearch({
                          name,
                          search_method,
                          value: search_values?.[name],
                        })
                      }
                    />
                  ))}
                {search_component === "select" && (
                  <ListSelect
                    title={title}
                    options={options}
                    value={search_values?.[name]}
                    onChange={(value) => [
                      setSearchValues({
                        ...search_values,
                        [name]: value,
                      }),
                      onSearch({
                        name,
                        search_method,
                        value,
                      }),
                    ]}
                  />
                )}
                {search_component === "date-picker" && (
                  <ListDatePicker
                    title={title}
                    value={search_values?.[name]}
                    onChange={(value) => [
                      setSearchValues({
                        ...search_values,
                        [name]: value,
                      }),
                      onSearch({
                        name,
                        search_method,
                        value,
                      }),
                    ]}
                  />
                )}
              </Col>
            )
          )}
      </Row>
      <Row
        style={{
          margin: "20px 0",
        }}
      >
        <Col xs={24} md={12}>
          <Select
            allowClear
            style={{
              width: 200,
              display: "block",
            }}
            placeholder="Sortuj"
            value={sort_options.find(
              ({ value }) => value === paginate?.sort
            )}
            onChange={(value) => {
              setPaginate({
                ...paginate,
                page: undefined,
                sort: value,
              });
            }}
            options={sort_options}
          />
        </Col>
        <Col xs={24} md={12} style={{ textAlign: "right" }}>
          <Space>
            <Typography.Text>
              Ilość: {total_count}
            </Typography.Text>
            <Button type="primary">Szukaj</Button>
            <Button
              onClick={() => [
                setQuery({}),
                setPaginate({
                  ...paginate,
                  page: undefined,
                  sort: undefined,
                }),
                setSearchValues({}),
              ]}
            >
              Resetuj
            </Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default Filters;
